@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: "Poppins", sans-serif;
  background-color: #EBECF0;
  height: 100%;
}


.background {
    background-image: url('../img/sawah.png');
    height: 100vh;
    background-size: cover;
    

}